import React from 'react';
import { connect } from 'react-redux';
import ContactDealer from '../../../components/contactDealer';
import { kbbBrand, TAB_NAME } from '../../../constants';
import getConfig from 'next/config';
import { useBrand } from 'reaxl-brand';
import { getSelectedTabFromState } from '../../../utilities';

const ContactDealerForm = ({
    isServiceTab,
}) => {
    const { publicRuntimeConfig } = getConfig();
    const { atcPrivatePolicy, kbbPrivatePolicy } = publicRuntimeConfig;
    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);
    const props = {
        heading: 'Contact Dealer',
        description: 'What can we help you with?',
        commentHeight: '70px',
        isModal: false,
        privatePolicyUrl: isKbb ? kbbPrivatePolicy : atcPrivatePolicy,
        isServiceTab,
    };
    return (<ContactDealer {...props} />);
};

function mapStateToProps(state) {
    const selectedTab = getSelectedTabFromState(state);
    const isServiceTab = (selectedTab?.name === TAB_NAME.SERVICE);

    return {
        isServiceTab,
    };
}
export default connect(mapStateToProps)(ContactDealerForm);
