import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import DealerDetailsNavigation from '../../components/dealerDetailsNavigation';
import { useBrand } from 'reaxl-brand';
import { kbbBrand } from '../../constants';
import { inventoryActiveOwnerDuck, dealerSearchPathDuck } from '../../ducks';

const DealerDetailsBottomNavContainer = ({
    ownerId,
    ownerWebsite,
    dealerUrl = '',
}) => {
    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);
    const props = {
        leftNav: { heading: 'See all dealers', url: isKbb ? `/${dealerUrl}/` : `/${dealerUrl}` },
        rightNav: { heading: 'Visit dealer website', url: ownerWebsite, ownerId },
    };
    return (
        (ownerWebsite || dealerUrl) && <DealerDetailsNavigation {...props} />
    );
};

function mapStateToProps(state) {
    const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    const owner = _get(state.owners, activeOwner, {});
    const ownerWebsite = _get(owner, 'website.href', '');
    const branchPath = dealerSearchPathDuck.selectors.getDuckState(state);
    const branchPathElements = branchPath?.split('/');
    const dealerUrl = branchPathElements && branchPathElements[1] ? branchPathElements[1] : '';
    return {
        ownerId: owner.id,
        ownerWebsite,
        dealerUrl,
    };
}

export default connect(mapStateToProps)(DealerDetailsBottomNavContainer);
