import React from 'react';
import { BookYourServiceKbb, BookYourServiceAtc } from '../images';
import { DealerCTA } from '.';
import { kbbBrand } from '../../../constants';

const BookYourService = ({
    buttonProps = { buttonTitle: 'Check Availability', vehicleType: 'new', onClick() { /* do nothing */ } },
    loading,
    title = 'Book Your Service',
    description = 'Save time when you book your maintenance appointment or repair.',
    isXs,
    error,
    pageName,
    brand,
    getAPriceQuoteUrl,
    routeUrl,
    flexItemsValues = {},
}) => {
    const isKbb = (brand === kbbBrand);
    const image = isKbb ? BookYourServiceKbb : BookYourServiceAtc;
    const imageWidth = isKbb ? 126 : 167;
    const imageHeight = isKbb ? 74 : 100;
    const props = {
        isXs,
        buttonProps,
        loading,
        title,
        description,
        error,
        pageName,
        brand,
        image,
        imageWidth,
        imageHeight,
        getAPriceQuoteUrl,
        routeUrl,
        flexItemsValues,
    };
    return (
        <DealerCTA {...props} />
    );
};

export default BookYourService;
