export default function getWidthAndHeight(clientWidth, setImageWidth, setImageHeight) {
    if (clientWidth >= 1280) {
        setImageWidth('500px');
        setImageHeight('321px');
    } else if (clientWidth >= 1024) {
        setImageWidth('395px');
        setImageHeight('254px');
    } else if (clientWidth >= 768) {
        setImageWidth('342px');
        setImageHeight('220px');
    } else if (clientWidth >= 550) {
        setImageWidth(`${clientWidth}px`);
        setImageHeight('220px');
    } else {
        setImageWidth(`${clientWidth}px`);
        setImageHeight('232px');
    }
}
