import React from 'react';
import { connect } from 'react-redux';
import { DealerDetailsContainer, DealerDetailsPathLocationContainer, DealerDetailsBottomNavContainer } from './dealerdetails';
import _get from 'lodash/get';
import { ContactDealerContainer } from './dealerdetails/contactdealer/index';
import { Section, GridContainer, colors } from '@argo/principles';
import { inventorySupportMakesDuck, inventoryActiveOwnerDuck } from '../ducks';

// fld and ddp share the owners state, that causes some problems on kbb branch,
// so we distinguish by franchiseName, just fld kbb have franchiseName
const DealerDetailsNewFeature = React.memo(({ owner = {}, makes }) => owner && (
    <Section
        fullWidth
        id="dealerDetailsNewSection"
        data-cmp="dealerDetailsNewSection"
        withGlobalNavSpacing
        className="section-main-bg"
        horizontalSpacingOverrides={{ left: '0', right: '0' }}
        verticalSpacingOverrides={{ top: '0', bottom: '0' }}
    >
        <GridContainer gap="md">
            <DealerDetailsContainer />
        </GridContainer>
        <Section
            data-cmp="contactDealerSection"
            fullWidth
            verticalSpacingOverrides={{ top: '40px', bottom: '64px' }}
            horizontalSpacingOverrides={{ left: '16px', right: '16px' }}
        >
            <ContactDealerContainer />
        </Section>
        <Section
            data-cmp="pathLocationSection"
            fullWidth
            bgColor={colors.neutral.offWhite}
            verticalSpacingOverrides={{ top: '40px', bottom: '64px' }}
            horizontalSpacingOverrides={{ left: '16px', right: '16px' }}
        >
            <div className="path-location">
                {makes.length > 0 ? (<DealerDetailsPathLocationContainer makes={makes} />) : ''}
                <DealerDetailsBottomNavContainer />
            </div>
        </Section>
    </Section>
));

function mapStateToProps(state) {
    const { owners } = state;
    const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    const owner = _get(owners, activeOwner, {});
    const makes = inventorySupportMakesDuck.selectors.getDuckState(state);

    return {
        owner,
        makes,
    };
}

export default connect(mapStateToProps)(DealerDetailsNewFeature);
