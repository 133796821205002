/* eslint-disable no-console */
import fetch from '@bonnet/next/fetch';

export default function emailFetcher(data) {
    return fetch('/car-dealers/email-service', {
        method: 'POST',
        body: JSON.stringify({ ...data, contactType: 'FYD' }),
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
        },
    })
        .then((response) => response.json());
}
