import React from 'react';
import styled from '@emotion/styled';
import { flexItemsValues, setMargin, setPadding } from '../dealerCTAConstant';
import { kbbBrand } from '../../../constants';

import { FlexBox } from '@argo/principles';
import { Paragraph, Heading, Button, Image } from '@argo/ui-elements';

const DealerCTAContainer = styled.div`
    ${setMargin(0)}
    padding-top: 24px;
    @media only screen and (max-width: 1024px) {
        padding-top: 16px;
    }
`;

const ImageContainer = styled.div`
    ${setPadding(0)}
    img {
        float: right;
    }
`;

const HeadingContainer = styled.div`
    ${setMargin(0)}
`;

const ButtonAtcContainer = styled.div`
    margin-left: 40px;
    @media only screen and (max-width: 900px) {
        margin-left: 0px;
    }
    @media only screen and (min-width: 1024px) {
        margin-left: 30px;
    }
    @media only screen and (min-width: 1300px) {
        margin-left: 0px;
    }
`;

const ButtonKbbContainer = styled.div`
    padding-top: 0px;
`;

const PlaceholderContainer = styled.div`
    padding-top: 0px;
`;

const renderButton = (props, buttonProps) => {
    const buttonType = props.isKbb ? 'tertiary' : 'primary';
    const analyticData = `results_${props.pageName}_pricerange_btn`;

    return (
        <Button
            buttonType={buttonType}
            text={buttonProps.buttonTitle}
            target="_self"
            enableCapitalize
            sizeType="regular"
            width="100%"
            data-testid={analyticData}
            onClick={buttonProps.onClick}
            {...buttonProps}
        />
    );
};

const renderButtonByBrand = (props, buttonProps) => {
    if (props.isKbb) {
        return (
            <ButtonKbbContainer>
                {renderButton(props, buttonProps)}
            </ButtonKbbContainer>
        );
    }
    return (
        <ButtonAtcContainer>
            {renderButton(props, buttonProps)}
        </ButtonAtcContainer>
    );
};

const DealerCTA = ({
    isXs,
    buttonProps,
    loading,
    title,
    description,
    image,
    imageWidth,
    imageHeight,
    error,
    pageName,
    brand,
}) => {
    const isKbb = (brand === kbbBrand);
    const props = { isKbb, pageName, isXs };

    return (
        <DealerCTAContainer data-cmp="dealerCTA">
            <FlexBox
                key="dealerCTAsFlex"
                alignItems="flex-start"
                default={flexItemsValues.default}
                flexWrap="wrap"
                verticalSpacing="sm"
                justifyContent="space-between"
                containerWidth="100%"
            >
                <ImageContainer>
                    <Image
                        src={`data:image/png;base64, ${image}`}
                        width={imageWidth}
                        height={imageHeight}
                    />
                </ImageContainer>
                {!loading && !error && (
                    <PlaceholderContainer>
                        <HeadingContainer>
                            <Heading
                                headingTag="h3"
                                headingName="ContentHeadingExtra"
                            >
                                {title}
                            </Heading>
                        </HeadingContainer>
                        <Paragraph types="body">
                            {description}
                        </Paragraph>
                    </PlaceholderContainer>
                )}
            </FlexBox>
            <FlexBox
                alignItems="center"
                default={flexItemsValues.default}
                flexWrap="wrap"
                verticalSpacing="sm"
                horizontalSpacing="none"
                justifyContent="center"
                containerWidth="100%"
            >
                {!isXs && <ButtonKbbContainer />}
                {renderButtonByBrand(props, buttonProps)}
            </FlexBox>
        </DealerCTAContainer>
    );
};

export default DealerCTA;
