/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import { useDevice } from '@atc/react-device';
import _get from 'lodash/get';
import { ContactDealerInfo, ContactDealerForm } from '.';
import { Section, FlexBox } from '@argo/principles';
import { inventoryActiveOwnerDuck } from '../../../ducks';

const ContactDealerContainer = ({
    owner,
}) => {
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);

    return (
        <Section
            data-cmp="contactDealerSection"
            fullWidth
            horizontalSpacingOverrides={{ left: '0px', right: '0px' }}
            verticalSpacingOverrides={{ top: '0px', bottom: '0px' }}
        >
            {owner.id && (
                <div className="contact-dealer">
                    <FlexBox
                        flexWrap="wrap"
                        default={[5, 7]}
                        md={['0 0 100%', '0 0 100%']}
                        verticalSpacing="none"
                        horizontalSpacing="none"
                    >
                        <div><ContactDealerInfo /></div>
                        {!isXs && <div><ContactDealerForm /></div>}
                    </FlexBox>
                </div>
            )}
        </Section>
    );
};

function mapStateToProps(state) {
    const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    const owner = _get(state.owners, activeOwner, {});

    return {
        owner,
    };
}

export default connect(mapStateToProps)(ContactDealerContainer);
