import React from 'react';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { useBrand } from 'reaxl-brand';
import { useRouter } from 'next/router';
import DealerCTAs from '../../../components/dealerCTAs';
import { kbbBrand, atcBrand } from '../../../constants/index';
import { mapDispatchToActionProp } from '@atc/modular-redux';
import { dealerTabsDuck, serviceDealerDuck, inventoryActiveOwnerDuck } from '../../../ducks';
import { TAB_NAME } from '../../../constants';
import { useDevice } from '@atc/react-device';
import { resetTab, getServiceTabFromState, getTabIndexByName } from '../../../utilities';
import { useAnalytics } from 'reaxl-analytics';

const DealerCTAsContainer = ({
    actions,
    owner,
    showServiceTab,
    salesTabIndex,
    servicesTabIndex,
    isPayingDealer,
}) => {
    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);
    const { sendClick } = useAnalytics();
    const router = useRouter();

    const handlePriceQuoteButtonClick = (event) => {
        actions.setSelectedTab(salesTabIndex, TAB_NAME.SALES);
        resetTab(router, TAB_NAME.SALES);
        sendClick('ownerEmailCTAClick', event, { ownerId: owner.id, labelKey: 'quote' });
    };

    const handleBookingServiceButtonClick = (event) => {
        actions.setSelectedTab(servicesTabIndex, TAB_NAME.SERVICE);
        resetTab(router, TAB_NAME.SERVICE);
        sendClick('ownerEmailCTAClick', event, { ownerId: owner.id, labelKey: 'confirmAvailabilityCTA' });
    };
    const props = {
        isPayingDealer,
        brand: isKbb ? kbbBrand : atcBrand,
        showFindYourNextCar: true,
        showBookYourService: showServiceTab,
        isXs,
        findYourNextCarProps: {
            buttonProps: {
                buttonTitle: 'Request A Price Quote',
                onClick: handlePriceQuoteButtonClick,
            },
        },
        bookYourServiceProps: {
            buttonProps: {
                buttonTitle: 'Check Availability',
                onClick: handleBookingServiceButtonClick,
            },
        },
    };

    return (
        <DealerCTAs
            data-cmp="dealerCTAsContainer"
            {...props}
        />
    );
};

function mapStateToProps(state) {
    const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    const owner = _get(state.owners, activeOwner, {});
    const showServiceTab = getServiceTabFromState(state);
    const salesTabIndex = getTabIndexByName(state, TAB_NAME.SALES);
    const servicesTabIndex = getTabIndexByName(state, TAB_NAME.SERVICE);
    const serviceDealerData = serviceDealerDuck.selectors.getDuckState(state);
    const isPayingDealer = _get(serviceDealerData, 'isPayingDealer', false);

    return {
        owner,
        showServiceTab,
        salesTabIndex,
        servicesTabIndex,
        isPayingDealer,
    };
}

const mapDispatchToProps = mapDispatchToActionProp({
    setSelectedTab: dealerTabsDuck.creators.setSelectedTab,
});

export default connect(mapStateToProps, mapDispatchToProps)(DealerCTAsContainer);
