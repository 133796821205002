import React from 'react';
import EmbeddedWidget from '../../../components/EmbeddedWidget/EmbeddedWidget';
import { colors, Section } from '@argo/principles';
import { TAB_NAME } from '../../../constants';

const ReviewsTabContainer = () => (
    <Section
        id="kbb_reviews"
        fullWidth
        bgColor={colors.neutral.offWhite}
        verticalSpacingOverrides={{ top: '0', bottom: '0' }}
        horizontalSpacingOverrides={{ left: '0', right: '0' }}
    >
        <EmbeddedWidget
            parentTab={TAB_NAME.REVIEWS}
        />

    </Section>
);

export default ReviewsTabContainer;
