import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import _get from 'lodash/get';
import OperatingHours from '../../../components/OperatingHours/OperatingHours';
import { FlexBox } from '@argo/principles';

import { Heading } from '@argo/ui-elements';
import {
    inventoryActiveOwnerDuck,
} from '../../../ducks';

const DealerHourSection = styled(FlexBox)`
    flex-direction: column;
    justify-Content: flex-start;
    flex-wrap: nowrap;
    p {
        font-weight: bold;
        color: black;
    }
    h2 {
        padding-bottom: 1%;
    }
`;

const SubHeadingSection = styled.div`
    margin-left: 0;
`;

const ContactDealerHours = ({
    owner = {},
}) => {
    const hours = _get(owner, 'hours', []);

    return (
        <div data-cmp="contactDealerHour">
            {hours.length > 0 && (
                <DealerHourSection>
                    <SubHeadingSection>
                        <Heading
                            headingTag="h2"
                            headingName="ContentHeadingExtra"
                        >
                            Service Hours
                        </Heading>
                        <OperatingHours hours={hours} />
                    </SubHeadingSection>
                </DealerHourSection>
            )}
        </div>
    );
};

function mapStateToProps(state) {
    const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    const owner = _get(state.owners, activeOwner, {});

    return {
        owner,
    };
}

export default connect(mapStateToProps)(ContactDealerHours);
