import React from 'react';
import styled from '@emotion/styled';
import { Theme, Row, Col } from '@argo/principles';
import { Paragraph, Heading } from '@argo/ui-elements';

const PathLocationSectionContainer = styled.div`
    margin: 0px;
`;

const renderContent = (data, split) => {
    if (split && data.length > 10) {
        const arrayLeft = [];
        const arrayRight = [];
        const endPoint = Math.round(data.length / 2);
        for (let index = 0; index < endPoint; index++) {
            const element = data[index];
            arrayLeft.push(element);
        }
        for (let index = endPoint; index < data.length; index++) {
            const element = data[index];
            arrayRight.push(element);
        }
        return (
            <Theme>
                <Row>
                    <Col sm={6}>
                        {
                            arrayLeft.map((item) => (
                                <Paragraph key={item}>{item}</Paragraph>
                            ))
                        }
                    </Col>
                    <Col
                        sm={6}
                    >
                        {
                            arrayRight.map((item) => (
                                <Paragraph key={item}>{item}</Paragraph>
                            ))
                        }
                    </Col>
                </Row>
            </Theme>
        );
    }
    return (data.map((item) => (
        <Paragraph key={item}>{item}</Paragraph>
    )));
};

const PathLocationSection = ({ heading, items, split = false }) => (
    <PathLocationSectionContainer>
        <Heading
            headingTag="h4"
            headingName="ContentHeadingExtra"
        >
            {heading}
        </Heading>
        {renderContent(items, split)}
    </PathLocationSectionContainer>
);

export default PathLocationSection;
