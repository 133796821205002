import React from 'react';
import styled from '@emotion/styled';

import { FlexBox } from '@argo/principles';
import { Heading, Paragraph } from '@argo/ui-elements';

const HomeServicesSectionContainer = styled.div`
`;

const HeadingContainer = styled.div`
    margin: 0px;
    padding: 0px;
`;

const ParagraphContainer = styled.div`
    margin: 0px;
    padding: 0px;
    max-width: 712px;
`;

const DealerHomeServiceSection = ({
    title = 'Dealer Home Services',
    description = 'We\'re all concerned about COVID-19. In response, our dealers have expanded the ways you can shop for a new or used car without leaving the safety and comfort of your home.',
    isServiceTab,
    isKbb,
}) => {
    title = isKbb ? 'Dealer Home Services' : 'At Home Services';
    return (
        <HomeServicesSectionContainer data-cmp="dealerHomeServiceSection">
            <FlexBox
                alignItems="start"
                flexWrap="wrap"
                verticalSpacing="none"
                horizontalSpacing="none"
                justifyContent="flex-start"
                containerWidth="100%"
                flowColumn
            >
                <HeadingContainer>
                    <Heading
                        headingTag="h2"
                        headingName="SubsectionHeadingExtra"
                    >
                        {title}
                    </Heading>
                </HeadingContainer>
                {!isServiceTab && (
                    <ParagraphContainer>
                        <Paragraph>{description}</Paragraph>
                    </ParagraphContainer>
                )}
            </FlexBox>
        </HomeServicesSectionContainer>
    );
};

export default DealerHomeServiceSection;
