import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { breadcrumbsForFindDealer } from '../../constants';
import { cloneDeep } from 'lodash';
import { withDevice } from '@bonnet/next/device';
import { atcBrand, kbbBrand } from '../../constants';
import { Breadcrumb as Breadcrumbs } from '@argo/base-patterns';
import styled from '@emotion/styled';
import { inventoryActiveOwnerDuck, dealerSearchPathDuck } from '../../ducks';

const BreadcrumbsContainer = styled.div`
    span {
        margin-top: 2px !important;
    }
`;

const DealerDetailsBreadcrumbs = ({
    breadcrumbs,
}) => (
    <BreadcrumbsContainer className="container breadcrumb-container">
        <Breadcrumbs
            id="breadcrumbs-container"
            breadcrumbItems={breadcrumbs}
        />
    </BreadcrumbsContainer>
);

function mapStateToProps(state) {
    const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    const owner = _get(state.owners, activeOwner, {});
    const asPath = dealerSearchPathDuck.selectors.getDuckState(state);
    const branchPath = asPath?.split('/')[1];
    const brand = _get(state, 'brand', atcBrand);
    let breadcrumbs = [];
    if (owner && owner.id) {
        const breadcrumbsElements = cloneDeep(breadcrumbsForFindDealer);
        const breadcrumbsWithDealer = breadcrumbsElements ?? [breadcrumbsForFindDealer[1]];
        breadcrumbsWithDealer.find((item) => item.label === 'Dealers').href = (brand === kbbBrand ? `/${branchPath}/` : `/${branchPath}`) || '/';
        breadcrumbsWithDealer.find((item) => item.label === 'Dealers').label = (brand === kbbBrand ? 'Dealers' : 'Car Dealers');
        breadcrumbs = breadcrumbsWithDealer.map((item) => ({ name: item.label, url: item.href }));
        breadcrumbs.push({ name: owner.name, url: '' });
    }
    return {
        breadcrumbs,
    };
}

function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.breadcrumbs) === JSON.stringify(nextProps.breadcrumbs);
}

DealerDetailsBreadcrumbs.displayName = 'DealerDetailsBreadcrumbs';

export default withDevice(connect(mapStateToProps)(React.memo(DealerDetailsBreadcrumbs, areEqual)));
