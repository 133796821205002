import React from 'react';
import styled from '@emotion/styled';
import { PathLocationSection } from './sections';
import { FlexBox } from '@argo/principles';

const DealerDetailsPathLocationContainer = styled.div`
    padding: 0px;
    .item-section-parent {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
    }
    .item-section-child {
        flex: 1 0 33%;
    }
    @media only screen and (max-width: 767px) {
        .item-section-child {
            padding-bottom: 24px;
            flex: 1 0 100%;
        }
        .item-section-child:last-of-type {
            padding-bottom: 0px;
            flex: 1 0 100%;
        }
    }
    @media only screen and (min-width: 768px) {
        .item-section-child {
            padding-right: 40px;
        }
    }
`;

const renderContent = (data) => {
    const splitItem = data.filter((item) => item.items?.length > 0)?.length < 3;
    return (data.map((item) => (item?.items?.length > 0 && (
        <div
            className="item-section-child"
            key={item.heading}
        >
            <PathLocationSection
                key={item.heading}
                split={splitItem}
                {...item}
            />
        </div>
    ))));
};

const DealerDetailsPathLocation = ({ data }) => (
    <DealerDetailsPathLocationContainer>
        <FlexBox
            flexWrap="wrap"
            verticalSpacing="none"
            horizontalSpacing="none"
            justifyContent="start"
            containerWidth="100%"
        >
            <div className="item-section-parent">
                {renderContent(data)}
            </div>
        </FlexBox>
    </DealerDetailsPathLocationContainer>
);

export default DealerDetailsPathLocation;
