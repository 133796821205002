/* global window */
import React, { useEffect, useState, useCallback } from 'react';
import getConfig from 'next/config';
import { setHeightForWidget } from '../../utilities';

const FdpqWidget = (
    {
        zipCode,
        pageName, /* `pageName` is only used for analytics. */
        isKbb,
        listingType = 'new',
        franchiseId,
        isXs,
        makeName,
        siteSection,
        pageType,
    }
) => {
    const [isClient, setIsClient] = useState(false);
    const [src, setSrc] = useState('');

    const sendMessageCallBack = useCallback(
        () => {
            const sendMessageEvent = (event) => setHeightForWidget(event, document, isXs);
            window.addEventListener('message', sendMessageEvent);
        }, [],
    );

    useEffect(() => {
        const { publicRuntimeConfig } = getConfig();
        const { fdpqKbbUrl, fdpqAtcUrl } = publicRuntimeConfig;
        const urlBase = isKbb ? fdpqKbbUrl : fdpqAtcUrl;
        const params = {
            zipCode,
            pageName,
            vehicleListingType: listingType,
            leadPartnerFranchiseId: franchiseId,
            origin: global?.origin,
            atcmakename: makeName,
            pagetype: siteSection,
            pageid: pageType,
        };
        const searchParams = new URLSearchParams(params);
        setSrc(`${urlBase}&${searchParams}`);
        setIsClient(true);
    }, []);

    useEffect(() => {
        sendMessageCallBack();
        return () => window.removeEventListener('message', sendMessageCallBack());
    }, [franchiseId]);

    return isClient ? (
        <iframe
            title="New Car Price Quote"
            id="my-iframe"
            src={src}
            frameBorder="0"
            width="100%"
            height="287"
            loading="lazy"
        />
    ) : null;
};

export default React.memo(FdpqWidget);
