import React from 'react';
import _get from 'lodash/get';
import { Link } from '@argo/ui-elements';

const AddressLink = ({ location, onAddressClick }) => {
    const address = _get(location, 'address', {});
    const mapUrl = _get(location, 'map.href', '');

    return (
        <Link
            href={mapUrl}
            target="_blank"
            rel="noopener"
            onClick={onAddressClick}
        >
            {`${address?.address1}, ${address?.city}, ${address?.state} ${address?.zip}`}
        </Link>
    );
};

export default React.memo(AddressLink);
