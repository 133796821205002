import { errorType, errorMsgs, inputType } from '../contactDealerConstant';
import { inputValidation } from '@argo/utils';

export function getErrorMessage(element, errorMessages) {
    const { value, required } = element;
    const dataInputType = element.getAttribute('data-input-type');
    const errorCode = inputValidation(dataInputType, value, required);
    const errorMessage = errorMsgs[dataInputType][errorType[errorCode]];
    if (dataInputType !== inputType.PhoneNumber || (value && dataInputType === inputType.PhoneNumber)) {
        errorMessages[dataInputType] = errorMessage;
    } else {
        errorMessages[dataInputType] = '';
    }
    return errorMessages;
}

export function isEmpty(str) {
    return (!str || str.length === 0);
}
