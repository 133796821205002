import React, { memo } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import { Section } from 'reaxl';

import { inventoryActiveOwnerDuck } from '../../ducks';

const DealerDisclaimerContainer = memo(({
    disclaimer,
}) => disclaimer && (
    <Section
        heading="Dealer Disclaimer"
        uiContext="section-4"
        alignment="left"
    >
        <p className="text-wrap">
            {disclaimer}
        </p>
    </Section>
));

function mapStateToProps(state) {
    const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    return {
        disclaimer: _get(state.owners, [activeOwner, 'disclaimer'], false),
    };
}

export default connect(mapStateToProps)(DealerDisclaimerContainer);
