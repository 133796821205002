import React from 'react';
import classnames from 'classnames';
import styled from '@emotion/styled';

import { Row, Col } from '@argo/principles';

/**
 *  The OperatingHours component is a display component that will take an array containing objects of with label, open, and close properties
*/
const ColDay = styled(Col)`
    flex-basis: 150px;
`;
const ColHours = styled(Col)`
    text-align: left;
    flex-basis: 200px;
`;
const OperatingHoursRow = styled(Row)`
    flex-wrap: inherit;
`;

const daysWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const usersDay = daysWeek[new Date().getDay()];

const formatOperatingHours = (open, close) => `${open} ${open && close ? '-' : ''} ${close}`;

const OperatingHours = ({ hours, ...rest }) => !!hours && (
    <>
        {hours.map((item, index) => (
            <OperatingHoursRow
                key={index}
                className={classnames({ 'text-bold': usersDay === item.label })}
            >
                <ColDay
                    className="day"
                    sm={6}
                >
                    {item.label}
                </ColDay>
                <ColHours
                    className="hours"
                    sm={6}
                >
                    {formatOperatingHours(item.open, item.close)}
                </ColHours>
            </OperatingHoursRow>
        ))}
    </>
);

export default OperatingHours;
