import React from 'react';
import styled from '@emotion/styled';
import { setMargin, setPadding } from '../dealerHomeServiceContants';
import { FlexBox } from '@argo/principles';
import { Paragraph, Heading, Image } from '@argo/ui-elements';

const ItemContainer = styled.div`
    ${setMargin(0)}
    ${setPadding(0)}
`;

const ImageContainer = styled.div`
    ${setPadding(0)}
    max-width: 126px;
`;

const HomeServiceDescription = styled.div`
    padding-left: 8px;
`;

const HeadingContainer = styled.div`
    ${setMargin(0)}
`;

const ParagraphContainer = styled.div`
    ${setMargin(0)}
`;

const EmptyContainer = styled.div`
    width: auto;
    @media only screen and (max-width: 768px) {
        display: none;
    }
`;

const HomeServicesItemCard = ({
    loading,
    title,
    description,
    image,
    imageWidth,
    imageHeight,
    error,
}) => (
    <ItemContainer>
        <FlexBox
            key="itemSection"
            alignItems="flex-start"
            default={[1, 3, 1]}
            flexWrap="wrap"
            verticalSpacing="none"
            horizontalSpacing="none"
            justifyContent="space-between"
            containerWidth="100%"
        >
            <ImageContainer>
                <Image
                    src={`data:image/png;base64, ${image}`}
                    width={imageWidth}
                    height={imageHeight}
                />
            </ImageContainer>
            {!loading && !error && (
                <HomeServiceDescription data-cmp="homeServiceDescription">
                    <HeadingContainer>
                        <Heading
                            headingTag="h3"
                            headingName="ContentHeadingExtra"
                        >
                            {title}
                        </Heading>
                    </HeadingContainer>
                    <ParagraphContainer>
                        <Paragraph types="body">
                            {description}
                        </Paragraph>
                    </ParagraphContainer>
                </HomeServiceDescription>
            )}
            <EmptyContainer />
        </FlexBox>
    </ItemContainer>
);

export default HomeServicesItemCard;
