import React, {
    useState,
    useEffect,
} from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { useDevice } from '@atc/react-device';
import {
    inventorySupportMakesDuck,
    showInventoryDuck,
    inventoryFiltersDuck,
    inventoryActiveOwnerDuck,
    birfDuck,
} from '../../../ducks';
import { getDevicePadding } from '../../../utilities/helpers';

import { Heading as AtcHeading } from 'reaxl';
import { useBrand } from 'reaxl-brand';
import { kbbBrand } from '../../../constants/index';

import dynamic from 'next/dynamic';
import LoadingFallback from '../../../components/LoadingFallback';
import LazyComponent from '../../../components/LazyComponent';

import {
    InventorySearchResultsContainer,
    DealerDisclaimerContainer,
    InventoryResultControlsContainer,
    InventoryFiltersContainer,
} from '..';
import FdpqWidget from '../../../components/FdpqWidget/FdpqWidget';

import { colors, Section } from '@argo/principles';
import { Heading } from '@argo/ui-elements';

const InventoryPaginationContainer = dynamic(() => import('../InventoryPaginationContainer'));

const renderFdpqWidget = ({ isXs, isKbb, zipCode, owner, makeName, padding, siteSection, pageType }) => (
    <div className="fdpq-container">
        <Section
            fullWidth
            bgColor={colors.neutral.offWhite}
            horizontalSpacingOverrides={{ left: padding, right: padding }}
            verticalSpacingOverrides={{ top: '0', bottom: '0' }}
        >
            <FdpqWidget
                isXs={isXs}
                isKbb={isKbb}
                zipCode={zipCode}
                pageName={isKbb ? 'ddp' : 'fyd_ddp'}
                listingType="new"
                franchiseId={owner.id}
                makeName={makeName}
                siteSection={siteSection}
                pageType={pageType}
            />
        </Section>
    </div>
);

const renderInventoryWidget = ({ InventoryPagination, isKbb, padding }) => (
    <div className="inventory-container">
        <Section
            fullWidth
            bgColor={colors.neutral.offWhite}
            horizontalSpacingOverrides={{ left: padding, right: padding }}
            verticalSpacingOverrides={{ top: '0px', bottom: '64px' }}
        >
            <div className="row margin-bottom-3">
                <div className="col-xs-12 col-md-9 padding-left-0 margin-top-0 margin-bottom-2 margin-top-md-2 margin-bottom-md-0">
                    {isKbb
                        ? (
                            <Heading
                                headingTag="h1"
                                headingName="SectionHeading"
                                enableBakeTitle
                            >
                                Vehicles in Stock
                            </Heading>
                        )
                        : (
                            <AtcHeading
                                size={600}
                                componentClass="div"
                                className="col-xs-12"
                            >
                                Dealer Vehicle Inventory
                            </AtcHeading>
                        )}
                </div>
                <div className="col-xs-12 col-md-3">
                    <InventoryResultControlsContainer />
                </div>
            </div>
            <div className="row margin-top-3">
                <InventoryFiltersContainer />
                <div className="col-xs-12 col-md-9">
                    <InventorySearchResultsContainer />
                    {InventoryPagination}
                    <DealerDisclaimerContainer />
                </div>
            </div>
        </Section>
    </div>
);

const SalesTabContainer = ({
    owner,
    isDisplayFdpq,
    makeName,
    showInventory,
    siteSection,
    pageType,
}) => {
    const [isClient, setIsClient] = useState(false);
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);
    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);
    const zipCode = _get(owner, 'location.address.zip', '');
    const padding = '0px';
    const containerPadding = getDevicePadding(isXs);

    useEffect(() => {
        setIsClient(true);
    }, []);

    const InventoryPagination = isClient ? (
        <LazyComponent
            renderOnScroll={false}
            fallback={<LoadingFallback height="max-content" />}
        >
            <InventoryPaginationContainer />
        </LazyComponent>
    ) : <LoadingFallback height="max-content" />;

    return (
        <Section
            id="car_sales"
            data-cmp="salesTabSection"
            fullWidth
            bgColor={colors.neutral.offWhite}
            verticalSpacingOverrides={{ top: '0', bottom: '0' }}
            horizontalSpacingOverrides={{ left: containerPadding, right: containerPadding }}
        >
            {isDisplayFdpq && renderFdpqWidget({
                isKbb,
                isXs,
                makeName,
                owner,
                padding,
                zipCode,
                siteSection,
                pageType,
            })}
            {showInventory && renderInventoryWidget({ InventoryPagination, isKbb, padding })}
        </Section>
    );
};

function mapStateToProps(state) {
    const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    const owner = _get(state.owners, activeOwner, {});
    const inventoryFilterData = inventoryFiltersDuck.selectors.getFiltersOptions(state);
    const supportMakes = inventorySupportMakesDuck.selectors.getDuckState(state);
    const nameplate = _get(owner, 'nameplate', []);
    const makes = _get(inventoryFilterData, 'options.makeCode.options', []);
    const make = makes.find((m) => m.value === nameplate[0]);
    const defaultMake = supportMakes.find((m) => m.value === make?.value) || supportMakes[0];
    const showInventory = showInventoryDuck.selectors.getDuckState(state);
    const birfData = birfDuck.selectors.getDuckState(state);
    const siteSection = _get(birfData, 'pageData.page.site.section', '');
    const pageType = _get(birfData, 'pageData.page.site.detailedPageName', '');

    return {
        owner,
        isDisplayFdpq: inventoryFilterData?.listingType?.options.some((x) => x.value === 'NEW' && x.count > 0),
        makeName: defaultMake?.label,
        showInventory,
        siteSection,
        pageType,
    };
}

export default connect(mapStateToProps)(SalesTabContainer);
