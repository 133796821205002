/* eslint-disable import/prefer-default-export,import/extensions */
export const getDevicePadding = (isXs) => (isXs ? '16px' : '0');

export const setWidth = (width) => `width: ${width};`;

export const setHeight = (height) => `height: ${height};`;

export const setPaddingTop = (paddingTop) => `padding-top: ${paddingTop}px;`;

export const setPaddingLeft = (paddingLeft) => `padding-left: ${paddingLeft}px;`;

export const setWidgetFont = (isKbb) => `
    font-family: ${isKbb ? '\'Montserrat-ExtraBold\', \'Montserrat ExtraBold\', \'Montserrat\', sans-serif' : '\'Roboto-Bold\', \'Roboto Bold\', \'Roboto\', sans-serif'} ;
    font-weight: ${isKbb ? '800' : '700'};
    font-size: ${isKbb ? '36px' : '24px'};
`;

export const setHomeServiceBackground = (isServiceTab) => `${isServiceTab ? 'background: white;' : 'background: #F2F2F2;'}`;
