import React from 'react';
import { LocalHomeDeliveryAtc, LocalHomeDeliveryKbb } from '../images';
import { HomeServicesItemCard } from '.';

const LocalHomeDeliverySection = ({
    isKbb,
    loading,
    title = 'Local Home Delivery',
    description = 'Start the purchase online and the dealer will deliver the car and the paperwork to you.',
    error,
}) => {
    const image = isKbb ? LocalHomeDeliveryKbb : LocalHomeDeliveryAtc;
    const imageWidth = isKbb ? 126 : 128;
    const imageHeight = isKbb ? 78 : 76;

    const props = {
        loading,
        title,
        description,
        error,
        image,
        imageWidth,
        imageHeight,
    };
    return (
        <HomeServicesItemCard {...props} />
    );
};

export default LocalHomeDeliverySection;
