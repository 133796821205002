import React, { useState, useEffect } from 'react';
import { useBrand } from 'reaxl-brand';
import { kbbBrand } from '../../constants/index';
import styled from '@emotion/styled';
import { colors } from '@argo/principles';
import { ChevronLeftIcon, ChevronRightIcon, SkeletonImage } from '@argo/ui-elements';
import RenderImage from './RenderImage';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { connect } from 'react-redux';
import { getSelectedTabFromState } from '../../utilities';

const ImageLayout = styled.div`
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-color: ${colors.neutral.white};
    ${({ imageWidth }) => `
        width: ${imageWidth};
        min-width: ${imageWidth};
    `};
    ${({ imageHeight }) => `
        min-height: ${imageHeight};
        height: ${imageHeight};
    `}
    .slide {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.6s ease;
    }
    .slide.active {
        opacity: 1;
    }
`;

const ThumbnailContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 0;
  list-style: none;
  width: 90%
`;

const PreviousButton = styled.div`
    box-sizing: border-box;
    padding: 9px 9px 9px 9px;
    border-radius: 100%;
    height: 35px;
    width: 35px;
    cursor: pointer;
    background-color: ${colors.neutral.white};
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
    z-index: 2;
    svg {
        g {
            polyline {
                stroke-width: 16px;
            }
        }
    }
`;

const NextButton = styled(PreviousButton)`
    left: auto;
    right: 10px;
`;

const DotCircle = styled.li`
    box-sizing: border-box;
    margin: 5px;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    background-color: #555555;
    list-style: none;
    ${({ isOpaque }) => `background-color: ${isOpaque ? '#fff' : ''};`}
`;

const WrapItemImage = styled.div`
  display: flex;
`;

const CarouselDealer = ({
    imageHeight,
    imageWidth,
    imageData = [],
    selectedTab,
}) => {
    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);
    const [imageCounter, setImageCounter] = useState(0);
    const [widthItem, setWidthItem] = useState(0);

    useEffect(() => {
        const dealerWrapper = document.getElementById('carouselDealer');
        if (dealerWrapper !== null) {
            setWidthItem(dealerWrapper.clientWidth);
        }
        forceCheck();
        return () => setWidthItem(0);
    }, [selectedTab, imageWidth]);
    return (
        <ImageLayout
            id="carouselDealer"
            data-cmp="carouselDealer"
            imageWidth={imageWidth}
            imageHeight={imageHeight}
        >

            {imageData && imageData.length > 1 && (
                <PreviousButton onClick={() => setImageCounter(imageCounter - 1 < 0 ? imageData.length - 1 : imageCounter - 1)}>
                    <ChevronLeftIcon
                        color={isKbb ? colors.action.darkBrightBlue : 'black'}
                        size="16"
                    />
                </PreviousButton>
            )}
            <WrapItemImage style={{ width: `${imageData.length * widthItem}px`, height: 'inherit' }}>
                {imageData.map((item, index) => {
                    const active = index === imageCounter;
                    return (
                        <LazyLoad
                            style={{ height: 'inherit' }}
                            scroll
                            resize
                            key={index}
                            placeholder={(
                                <SkeletonImage
                                    height={imageHeight}
                                    width={imageWidth}
                                    inverse
                                />
                            )}
                        >
                            <RenderImage
                                item={item}
                                index={index}
                                widthItem={widthItem}
                                active={active}
                                imageWidth={imageWidth}
                                imageHeight={imageHeight}
                            />
                        </LazyLoad>

                    );
                })}
            </WrapItemImage>
            {imageData && imageData.length > 1 && (
                <NextButton onClick={() => setImageCounter(imageCounter + 1 > imageData.length - 1 ? imageData.length - imageData.length : imageCounter + 1)}>
                    <ChevronRightIcon
                        color={isKbb ? colors.action.darkBrightBlue : 'black'}
                        size="16"
                    />
                </NextButton>
            )}

            {imageData && imageData.length > 1 && (
                <ThumbnailContainer>
                    {imageData && imageData.map((_, i) => (
                        <DotCircle
                            key={`dotC+${i}`}
                            isOpaque={i !== imageCounter}
                        />
                    ))}
                </ThumbnailContainer>
            )}
        </ImageLayout>
    );
};
function mapStateToProps(state) {
    const selectedTab = getSelectedTabFromState(state);
    return {
        selectedTab,
    };
}
export default connect(mapStateToProps)(CarouselDealer);
