import React, { memo } from 'react';
import { connect } from 'react-redux';
import { useDevice } from '@bonnet/next/device';

import {
    Filters,
    FilterLocation,
    FilterPriceDisclaimer,
    FilterPriceRange,
    FilterSelect,
    FilterYearRange,
} from 'reaxl-filters';

import { mapDispatchToActionProp } from '@atc/modular-redux';
import { pageNames } from '@atc/bonnet-paths';

import {
    inventoryFiltersDuck,
    inventoryPaginationDuck,
    inventoryResultsDuck,
} from '../../ducks';
import useCreateDealerDetailsPageEvent from '../../hooks/useCreateDealerDetailsPageEvent';
import { rebuildDealerDetailsPage } from '../../actions';
import { useAnalytics } from 'reaxl-analytics';
import { useRouter } from 'next/router';

const filtersOrder = [
    'listingTypes',
    'listingType',
    'yearRange',
    'mileage',
    'makeCodeList',
    'makeCode',
    'modelCodeList',
    'modelCode',
    'trimCodeList',
    'trimCode',
    'seriesCodeList',
    'seriesCode',
    'priceRange',
    'vehicleStyleCodes',
    'vehicleStyleCode',
    'driveGroup',
    'transmissionCodes',
    'transmissionCode',
    'engineCodes',
    'engineCode',
    'fuelTypeGroup',
    'mpgRanges',
    'mpgRange',
    'extColorsSimple',
    'extColorSimple',
    'interiorColorsSimple',
    'intColorSimple',
    'featureCodes',
    'featureCode',
    'listingFeatures',
    'listingFeature',
    'dealTypes',
    'dealType',
    'doorCodes',
    'doorCode',
];

const InventoryFiltersContainer = memo(({
    actions,
    filters: filtersOptions,
    isFiltersVisible,
    resultCount,
    values,
}) => {
    const device = useDevice();
    const isFiltersCollapsed = device.lessThan.md;
    const isCollapsedFiltersActive = device.lessThan.md && isFiltersVisible;
    const createDealerDetailsPageEvent = useCreateDealerDetailsPageEvent();
    const { sendClick } = useAnalytics();
    const router = useRouter();

    const handleOptionChange = async (event, filter) => {
        // make sure we persist event when we can
        if (event && typeof event.persist === 'function') {
            event.persist();
        }
        sendClick('filterClick', event, { filterName: filter.name, pageName: pageNames.DEALER_DETAILS, filterDuck: inventoryFiltersDuck });

        actions.applyFilterChange(event, filter);
        actions.resetPagination();
        const data = await actions.rebuildDealerDetailsPage(router.push);
        createDealerDetailsPageEvent({ ...data, event });
    };

    const handleClearFilter = async (event, { name }) => {
        actions.clearFilter(name);
        const data = await actions.rebuildDealerDetailsPage(router.push);
        createDealerDetailsPageEvent({ ...data, event });
    };

    const { yearRange, mileage, dealTypes, mpgRange } = filtersOptions;

    const aggregatedFilters = {
        location: {
            collapsed: false,
            component: FilterLocation,
            label: 'Location',
            name: 'location',
        },
        ...(yearRange && {
            yearRange: {
                ...yearRange,
                component: FilterYearRange,
            },
        }),
        priceRange: {
            collapsed: false,
            component: FilterPriceRange,
            label: 'Price Range',
            name: 'priceRange',
        },
        ...(mileage && {
            mileage: {
                component: FilterSelect,
                ...mileage,
            },
        }),
        ...(dealTypes && {
            dealTypes: {
                ...dealTypes,
                children: <FilterPriceDisclaimer />,
            },
        }),
        ...(mpgRange && {
            mpgRange: {
                ...mpgRange,
                component: FilterSelect,
            },
        }),
    };

    const collapsedTitle = `${resultCount} Matches`;

    return (
        <div className="col-xs-12 col-md-3">
            { values
                && (
                    <Filters
                        show={isCollapsedFiltersActive}
                        collapsed={isFiltersCollapsed}
                        collapsedTitle={collapsedTitle}
                        filters={{
                            ...filtersOptions,
                            ...aggregatedFilters,
                        }}
                        filtersOrder={filtersOrder}
                        values={values}
                        onHide={actions.hideFilters}
                        onOptionChange={handleOptionChange}
                        onOptionsClear={handleClearFilter}
                    />
                )}
        </div>
    );
});

function mapStateToProps(state) {
    return {
        resultCount: inventoryResultsDuck.selectors.getResultsCount(state),
        filters: inventoryFiltersDuck.selectors.getFiltersOptions(state),
        values: inventoryFiltersDuck.selectors.getFiltersValues(state),
        isFiltersVisible: inventoryFiltersDuck.selectors.getFiltersVisible(state),
    };
}

const mapDispatchToProps = mapDispatchToActionProp({
    applyFilterChange: inventoryFiltersDuck.creators.applyFilterChange,
    clearFilter: inventoryFiltersDuck.creators.clearFilter,
    hideFilters: inventoryFiltersDuck.creators.hideFilters,
    resetPagination: inventoryPaginationDuck.creators.resetPagination,
    rebuildDealerDetailsPage,
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryFiltersContainer);
