import React from 'react';
import { Link } from '@argo/ui-elements';
import { formattedPhoneNumber } from 'atc-js';
import styled from '@emotion/styled';

const setPhoneColor = (isXs) => !isXs && `
    > a {
        color: #000000 !important;
    }
`;

const PhoneNumberContainer = styled.div`
    pointer-events: ${(p) => (p.isXs ? 'auto' : 'none')};
    ${({ isXs }) => setPhoneColor(isXs)}
`;

const PhoneLink = ({ phoneNumber, isXs, onPhoneClick }) => {
    const phoneNumberData = formattedPhoneNumber(phoneNumber);
    const href = `tel:${phoneNumber}`;

    return (
        <PhoneNumberContainer
            data-cmp="header-phoneNumber"
            isXs={isXs}
        >
            <Link
                href={href}
                onClick={onPhoneClick}
            >
                {phoneNumberData}
            </Link>
        </PhoneNumberContainer>
    );
};

export default React.memo(PhoneLink);
