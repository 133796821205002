import React from 'react';
import { VideoWalkaroundAtc, VideoWalkaroundKbb } from '../images';
import { HomeServicesItemCard } from '.';

const VideoWalkaroundSection = ({
    isKbb,
    loading,
    title = 'Video Walkaround',
    description = 'Experience the car via video chat and ask the dealer any questions you may have.',
    error,
}) => {
    const image = isKbb ? VideoWalkaroundKbb : VideoWalkaroundAtc;
    const imageWidth = isKbb ? 125 : 128;
    const imageHeight = isKbb ? 53 : 79;

    const props = {
        loading,
        title,
        description,
        error,
        image,
        imageWidth,
        imageHeight,
    };
    return (
        <HomeServicesItemCard {...props} />
    );
};

export default VideoWalkaroundSection;
