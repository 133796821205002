import React from 'react';
import styled from '@emotion/styled';
import { FindYourNextCar, BookYourService } from './sections';

const DealerCTAsContainer = styled.div`
    padding: 0px 7% 0px 7%;
    @media only screen and (max-width: 1024px) {
        padding: 0px 0% 0px 0%;
        >div:nth-of-type(1) {
            img {
                width: 102px;
                height: 43px;
            }
        }

        >div:nth-of-type(2) {
            img {
                width: 110px;
                height: 80px;
            }
        }
    }
`;

const DealerCTAs = (dealerCTAProps) => {
    const { showFindYourNextCar, findYourNextCarProps, brand, isXs, bookYourServiceProps, isPayingDealer } = dealerCTAProps;

    return (
        <DealerCTAsContainer data-cmp="dealerCTAs">
            {showFindYourNextCar && (
                <FindYourNextCar
                    key="findYourNextCarSection"
                    {...findYourNextCarProps}
                    brand={brand}
                    isXs={isXs}
                />
            )}
            {isPayingDealer && (
                <BookYourService
                    key="bookYourServiceSection"
                    {...bookYourServiceProps}
                    brand={dealerCTAProps.brand}
                    isXs={dealerCTAProps.isXs}
                />
            )}
        </DealerCTAsContainer>
    );
};

DealerCTAs.defaultProps = {
    data: {},
    lazyImage: false,
    noSlashOnRoute: false,
    pageName: '',
    intent: '',
    isPayingDealer: false,
};

DealerCTAs.displayName = 'Applied Pattern - DealerCTAs';

export default DealerCTAs;
