/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
// import { DealerHomeServicesContainer } from '.';
import ServiceCTAs from '../../../components/ServiceCTAs/ServiceCTAs';
import { useBrand } from 'reaxl-brand';
import { kbbBrand } from '../../../constants/index';
import { inventoryActiveOwnerDuck } from '../../../ducks';

const ServicesTabContainer = ({
    owner,
    homeServices,
}) => {
    // const { maintenanceValet } = homeServices;
    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);
    return (
        <div id="service">
            <ServiceCTAs
                isKbb={isKbb}
            />
            {/* {maintenanceValet && <DealerHomeServicesContainer />} */}
        </div>
    );
};

function mapStateToProps(state) {
    const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    const owner = _get(state.owners, activeOwner, {});
    const homeServices = _get(state.owners, `${activeOwner}.homeServices`, {});

    return {
        homeServices,
        owner,
    };
}

export default connect(mapStateToProps)(ServicesTabContainer);
