/* eslint-disable import/prefer-default-export */
export const dealerHomeServiceTitle = 'Dealer Home Services';
export const disclaimer = 'All Dealer Home Services must comply with state or local ordinances. For important participation details and mileage limitations, please ask your dealer.';
export const disclaimerMoneyBackDefault = '*At least 3 days and 250 miles, but some dealers offer more. Vehicle must be returned in the same condition. Shipping or other fees may not be refunded. Guarantee is provided solely by dealer and may be discontinued at any time.';
export const flexItemsValues = {
    default: ['1', '2', '0 0 100%', '1', '0 0 80%'],
};
export const validServices = ['maintenanceValet', 'testDrive', 'vehicleDelivery', 'virtualTour'];
export const setItemSectionChild = (percent, paddingTop, marginTop) => (`flex: 1 0 ${percent}%; padding-top: ${paddingTop}px; margin-top: ${marginTop}px;`);
export const setPadding = (padding) => (`padding: ${padding}px;`);
export const setMargin = (margin) => (`margin: ${margin}px;`);
export const setMarginTop = (marginTop) => (`margin-top: ${marginTop}px !important;`);
export const setMarginLeft = (marginLeft) => (`margin-left: ${marginLeft}px !important;`);
