import React, { useState, useEffect } from 'react';
import { getScaledImageSource } from 'atc-js';
import styled from '@emotion/styled';
import { SkeletonImage } from '@argo/ui-elements';
import { setWidth, setHeight } from '../../utilities/helpers';
import getImageOrFallback from './getImageOrFallback';

const StyledImage = styled.img`
  object-fit: cover;
  background-size: cover;
  min-width: 100%;
  min-height: 100%;
  ${({ imageWidth }) => setWidth(imageWidth)}
  ${({ imageHeight }) => setHeight(imageHeight)}
`;

const getScaleImageSize = (defaultWidth, defaultHeight, naturalWidth, naturalHeight) => ({
    width: naturalWidth < defaultWidth ? naturalWidth : defaultWidth,
    height: naturalHeight < defaultHeight ? naturalHeight : defaultHeight,
});

const RenderImage = ({ item, index, widthItem, active, imageWidth, imageHeight }) => {
    const { src } = item;
    const [imageSrc, setImageSrc] = useState('');
    const [scaleWidth, setScaleWidth] = useState('');
    const [scaleHeight, setScaleHeight] = useState('');
    const defaultWidth = imageWidth?.replace('px', '');
    const defaultHeight = imageHeight?.replace('px', '');
    const scaler = getScaledImageSource({ ...item, width: defaultWidth, height: defaultHeight });

    useEffect(() => {
        (async () => {
            const { imgSrc, naturalWidth, naturalHeight } = await getImageOrFallback(scaler, src);
            const { width, height } = getScaleImageSize(defaultWidth, defaultHeight, naturalWidth, naturalHeight);
            setScaleWidth(width + 'px');
            setScaleHeight(height + 'px');
            setImageSrc(imgSrc);
        })();
    }, []);

    if (imageSrc?.length === 0) {
        return (
            <SkeletonImage
                key={index}
                height={imageHeight}
                width={imageWidth}
                inverse
            />
        );
    }

    return (
        <div
            style={{
                left: `-${index * widthItem}px`,
                width: `${widthItem}px`,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                height: 'inherit',
                alignItems: 'center',
            }}
            className={active ? 'slide active' : 'slide'}
            key={index}
        >
            <div style={{ width: scaleWidth }}>
                <StyledImage
                    src={imageSrc}
                    imageWidth={scaleWidth}
                    imageHeight={scaleHeight}
                    alt={item.alt}
                />
            </div>
        </div>
    );
};
export default RenderImage;
