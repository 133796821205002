/* eslint-disable import/prefer-default-export */
export const requiredMessage = 'This field is required.';

export const mask = {
    PhoneNumber: '000-000-0000',
    Email: '',
    FirstName: '',
    LastName: '',
    Comments: '',
    Default: '',
};

export const type = {
    PhoneNumber: 'tel',
    Email: 'email',
    FirstName: 'text',
    LastName: 'text',
    Comments: 'textarea',
};

export const inputType = {
    PhoneNumber: 'PhoneNumber',
    Email: 'Email',
    FirstName: 'FirstName',
    LastName: 'LastName',
    Comments: 'Comments',
    Subject: 'Subject',
};

export const errorType = {
    0: 'FormatError',
    1: 'LengthError',
    2: 'RequiredError',
    3: 'NumericalError',
};

export const errorMsgs = {
    PhoneNumber: {
        FormatError: 'Invalid Phone Number format.',
        LengthError: 'Phone Number must be 10 digits.',
        RequiredError: 'This field is required.',
        NumericalError: 'Phone Number must be digit only.',
    },
    Email: {
        FormatError: 'Invalid Email format.',
        RequiredError: 'This field is required.',
        LengthError: 'Email must be less than or equal 50 characters.',
    },
    FirstName: {
        FormatError: 'First Name contains invalid character.',
        LengthError: 'First Name must be less than or equal 50 characters.',
        RequiredError: 'This field is required.',
    },
    LastName: {
        FormatError: 'Last Name contains invalid character.',
        LengthError: 'Last Name must be less than or equal 50 characters.',
        RequiredError: 'This field is required.',
    },
    Comments: {
        LengthError: 'Comment must be less than or equal 1000 characters.',
        RequiredError: 'This field is required.',
    },
};
