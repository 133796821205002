import React, { memo } from 'react';
import { kbbBrand } from '../../constants';
import styled from '@emotion/styled';
import { validServices, disclaimer, setItemSectionChild, setMarginLeft } from './dealerHomeServiceContants';
import {
    DealerHomeServiceSection,
    VideoWalkaroundSection,
    MaintenanceFromYourDoorSection,
    LocalHomeDeliverySection,
    TestDriveAtHomeSection,
} from './sections';

import { colors, FlexBox, Section } from '@argo/principles';
import { Paragraph } from '@argo/ui-elements';
import { setHomeServiceBackground } from '../../utilities/helpers';

const DealerHomeServicesContainer = styled(Section)`
    display: flex;
    flex-direction: row;
    position: relative;
    &::after {
        content: '';
        width: 100vw ;
        position: absolute;
        height: 100%;
        ${({ isServiceTab }) => setHomeServiceBackground(isServiceTab)}
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
        z-index: 1;
    }
    >*{
        position: relative;
        z-index: 2;
    }

    .item-section-parent {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 12px 0 0 0;
    }
    .item-section-child {
        ${setItemSectionChild(50, 12, 0)};
    }

    @media only screen and (max-width: 768px) {
        >div {
            img {
                width: 120px;
            }
        }
    }

    @media only screen and (max-width: 650px) {
        .item-section-child {
            flex: 1 0 100%;
        }
        >div {
            img {
                width: 120px;
            }
        }
    }

    @media only screen and (min-width: 768px) {
        ${setMarginLeft(-10)}
    }
`;

const DisclaimerContainer = styled.p`
    font-size: 12px;
    max-width: 712px;
`;

const ParagraphContainer = styled.div`
    margin-top: 16px;
    p {
        margin: 0px;
    }
`;

const DealerHomeServices = memo(({
    services = {},
    brand = '',
    isServiceTab = false,
}) => {
    const { maintenanceValet, testDrive, vehicleDelivery, virtualTour } = services;
    const props = { isKbb: brand === kbbBrand };
    const maintenanceValetProps = { ...props, isServiceTab };

    const childClassName = 'item-section-child';
    const filteredServices = Object.keys(services).filter((key) => validServices.indexOf(key) >= 0)
        .reduce((cur, key) => Object.assign(cur, { [key]: services[key] }), {});
    const availableServices = Object.values(filteredServices).filter((value) => value).length;

    return (
        <DealerHomeServicesContainer
            fullWidth
            data-cmp="dealerHomeServiceSection"
            horizontalSpacingOverrides={{ left: '16px', right: '16px' }}
            verticalSpacingOverrides={{ top: '40px', bottom: '64px' }}
            bgColor={isServiceTab ? colors.neutral.white : colors.neutral.offWhite}
            isServiceTab={isServiceTab}
        >
            <FlexBox
                alignItems="center"
                flexWrap="wrap"
                verticalSpacing="none"
                horizontalSpacing="none"
                justifyContent="space-between"
                containerWidth="100%"
            >
                <DealerHomeServiceSection {...maintenanceValetProps} />
                <FlexBox
                    flexWrap="wrap"
                    verticalSpacing="none"
                    horizontalSpacing="none"
                    justifyContent="space-between"
                    containerWidth="100%"
                >
                    <div className="item-section-parent">
                        {virtualTour && (
                            <div className={childClassName}>
                                <VideoWalkaroundSection {...props} />
                            </div>
                        )}
                        {maintenanceValet && (
                            <div className={childClassName}>
                                <MaintenanceFromYourDoorSection {...maintenanceValetProps} />
                            </div>
                        )}
                        {vehicleDelivery && (
                            <div className={childClassName}>
                                <LocalHomeDeliverySection {...props} />
                            </div>
                        )}
                        {testDrive && (
                            <div className={childClassName}>
                                <TestDriveAtHomeSection {...props} />
                            </div>
                        )}
                        {
                            !isServiceTab && (availableServices % 2 !== 0 && availableServices > 1) && <div className={childClassName} />
                        }
                    </div>
                </FlexBox>
                <ParagraphContainer>
                    <FlexBox
                        flexWrap="wrap"
                        justifyContent="start"
                        containerWidth="100%"
                        verticalSpacing="none"
                        horizontalSpacing="none"
                    >
                        <Paragraph>
                            <DisclaimerContainer>
                                {disclaimer}
                            </DisclaimerContainer>
                        </Paragraph>
                    </FlexBox>
                </ParagraphContainer>
            </FlexBox>
        </DealerHomeServicesContainer>
    );
});

export default DealerHomeServices;
