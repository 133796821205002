import React from 'react';
import { MaintenanceFromYourDoorAtc, MaintenanceFromYourDoorKbb } from '../images';
import { HomeServicesItemCard } from '.';

const MaintenanceFromYourDoorSection = ({
    isKbb,
    isServiceTab,
    loading,
    title = 'Maintenance from Your Door',
    description = isServiceTab
        ? 'Once you own the car and need service, this dealer will pick it up, do the work and bring your vehicle back. Ask your dealer about this service.'
        : 'Once you own the car and need service, this dealer will pick it up, do the work and bring your vehicle back.',
    error,
}) => {
    const image = isKbb ? MaintenanceFromYourDoorKbb : MaintenanceFromYourDoorAtc;
    const imageWidth = 126;
    const imageHeight = isKbb ? 94 : 75;

    const props = {
        loading,
        title,
        description,
        error,
        image,
        imageWidth,
        imageHeight,
    };
    return (
        <HomeServicesItemCard {...props} />
    );
};

export default MaintenanceFromYourDoorSection;
