import React from 'react';
import { FindYourNextCarKbb, FindYourNextCarAtc } from '../images';
import { DealerCTA } from '.';
import { kbbBrand } from '../../../constants';

const FindYourNextCar = ({
    buttonProps = { buttonTitle: 'Request A Price Quote', vehicleType: 'new', onClick() { /* do nothing */ } },
    loading,
    title = 'Find Your Next Car',
    description = 'Got a particular new car in mind? We\'ve either got it - or we can get it.',
    isXs,
    error,
    pageName,
    brand,
    getAPriceQuoteUrl,
    routeUrl,
    flexItemsValues = {},
}) => {
    const isKbb = (brand === kbbBrand);
    const image = isKbb ? FindYourNextCarKbb : FindYourNextCarAtc;
    const imageWidth = isKbb ? 136 : 163;
    const imageHeight = isKbb ? 57 : 74;

    const props = {
        isXs,
        buttonProps,
        loading,
        title,
        description,
        error,
        pageName,
        brand,
        image,
        imageWidth,
        imageHeight,
        getAPriceQuoteUrl,
        routeUrl,
        flexItemsValues,
    };
    return (
        <DealerCTA {...props} />
    );
};

export default FindYourNextCar;
