import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getSelectedTabFromState } from '../utilities';

const KbbDealerRatingBadge = ({ kbbDealerRatingUrl, height = '22px', width = '223px', dataCmp = '', className = '', isImpression, selectedTab }) => {
    const [displayWidget, setDisplayWidget] = useState(false);
    useEffect(() => {
        if (isImpression) {
            setTimeout(() => { setDisplayWidget(true); }, 500);
        } else {
            setDisplayWidget(true);
        }
        return () => setDisplayWidget(false);
    }, [selectedTab.name]);

    if (displayWidget) {
        return (
            <iframe
                title="Dealer Rating Badge"
                width={width}
                height={height}
                scrolling="no"
                frameBorder="0"
                loading="lazy"
                src={kbbDealerRatingUrl}
                data-cmp={dataCmp}
                className={className}
            />
        );
    }
    return <></>;
};

function mapStateToProps(state) {
    const selectedTab = getSelectedTabFromState(state);
    return {
        selectedTab,
    };
}

export default connect(mapStateToProps)(KbbDealerRatingBadge);
