import React from 'react';
import { connect } from 'react-redux';
import { mapDispatchToActionProp } from '@atc/modular-redux';
import { showContactDealerDuck } from '../../../ducks';
import { kbbBrand } from '../../../constants';
import ContactDealer from '../../../components/contactDealer';
import styled from '@emotion/styled';
import getConfig from 'next/config';
import { useBrand } from 'reaxl-brand';

import { Overlay, Popover } from '@argo/base-patterns';

const OverlayContainer = styled.div`
    padding: 0px;
`;

const ContactDealerModal = ({
    actions,
    isSsuccess,
    mesage,
    showing,
}) => {
    const { publicRuntimeConfig } = getConfig();
    const { atcPrivatePolicy, kbbPrivatePolicy } = publicRuntimeConfig;
    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);
    const props = {
        description: 'What can we help you with?',
        commentHeight: '140px',
        isModal: true,
        privatePolicyUrl: isKbb ? kbbPrivatePolicy : atcPrivatePolicy,
    };

    if (mesage.length > 0 && showing) {
        return (
            <OverlayContainer>
                <Overlay
                    data-cmp="contactDealerOverlay"
                    showOverlay={showing}
                    widthRatio={90}
                    maxHeightRatio={50}
                    maxWidth="500px"
                    useOpenContent={false}
                    onClose={() => { actions.setShowingContactDealer({ isSsuccess: false, mesage: '', showing: false }); }}
                    header="Email Confirmation"
                >
                    <Popover
                        show={showing}
                        caretPosition="left"
                        width="100%"
                        color={isSsuccess ? 'green' : 'charcoal'}
                        text={mesage}
                    />
                </Overlay>
            </OverlayContainer>
        );
    }
    return (
        <OverlayContainer>
            <Overlay
                data-cmp="contactDealerOverlay"
                showOverlay={showing}
                header="Contact Dealer"
                headingStyle="SubsectionHeadingExtra"
                headingTag="h3"
                enableTitleCase
                widthRatio={90}
                maxHeightRatio={120}
                maxWidth="1000px"
                useOpenContent={false}
                onClose={() => { actions.setShowingContactDealer({ isSsuccess: false, mesage: '', showing: false }); }}
            >
                <ContactDealer {...props} />
            </Overlay>
        </OverlayContainer>
    );
};

function mapStateToProps(state) {
    const { isSsuccess, mesage, showing } = showContactDealerDuck.selectors.getDuckState(state);

    return {
        isSsuccess,
        mesage,
        showing,
    };
}

const mapDispatchToProps = mapDispatchToActionProp({
    setShowingContactDealer: showContactDealerDuck.creators.setShowingContactDealer,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactDealerModal);
