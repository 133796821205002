import React from 'react';
import { TestDriveAtHomeAtc, TestDriveAtHomeKbb } from '../images';
import { HomeServicesItemCard } from '.';

const TestDriveAtHomeSection = ({
    isKbb,
    loading,
    title = 'Test Drive at Home',
    description = 'Choose a car and the dealer will bring it to you for a no-obligation test drive.',
    error,
}) => {
    const image = isKbb ? TestDriveAtHomeKbb : TestDriveAtHomeAtc;
    const imageWidth = isKbb ? 127 : 116;
    const imageHeight = isKbb ? 85 : 69;

    const props = {
        loading,
        title,
        description,
        error,
        image,
        imageWidth,
        imageHeight,
    };
    return (
        <HomeServicesItemCard {...props} />
    );
};

export default TestDriveAtHomeSection;
