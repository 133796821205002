import { useEffect } from 'react';

import useCreateDealerDetailsPageEvent from '../../hooks/useCreateDealerDetailsPageEvent';

const DealerDetailsAnalyticsContainer = () => {

    const createDealerDetailsPageEvent = useCreateDealerDetailsPageEvent();
    // on mount declare a new page
    useEffect(() => {
        createDealerDetailsPageEvent({ isInitialLoad: true });
    }, []);

    return null;
};

export default DealerDetailsAnalyticsContainer;
